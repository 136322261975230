import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalOption, ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent implements OnInit, OnDestroy {
  modalOpen = false;
  modalData: ModalOption | null = null;
  loading: boolean = false;

  constructor(private modalService: ModalService) {}

  private _unsubscribeAll: Subject<unknown> = new Subject<unknown>();

  ngOnInit(): void {
    this.modalService.data$.subscribe(data => {
      this.modalData = data;
    });
    this.modalService.status$.subscribe(status => {
      this.modalOpen = status;
      this.toggleBodyScroll(status);
    });
    this.modalService.loading$.subscribe(loading => {
      this.loading = loading;
    });
  }

  ngOnDestroy(): void {
    this.toggleBodyScroll(false);
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  toggleBodyScroll(enable: boolean): void {
    const body = document.body;
    if (enable) {
      body.classList.add('modal-open');
    } else {
      body.classList.remove('modal-open');
    }
  }

  onClickAction(handler?: () => Promise<void>): void {
    const effectiveHandler = handler ? handler : () => Promise.resolve();
    this.modalService.action(effectiveHandler);
  }

  onClickClose(): void {
    this.modalService.close();
  }
}
